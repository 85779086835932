<div class="container">
  <q9-toolbar>
    <div q9-left-content>
      <q9ma-space-navigation></q9ma-space-navigation>
    </div>

    <div q9-right-content>
      <q9-theme-switcher></q9-theme-switcher>
      <ng-container *ngTemplateOutlet="settings"></ng-container>
      <q9-reports (reportAction)="onChange($event)"></q9-reports>
      <q9-notifications></q9-notifications>
      <q9-trainings></q9-trainings>
      <q9-support-button></q9-support-button>
      <q9-profile-menu>
        <button mat-menu-item (click)="navigateTo('profile')">
          {{ 'HEADER.PROFILE' | translate }}
        </button>
        <button mat-menu-item (click)="openSysAdminApp()" *ngIf="isSysAdmin()">
          {{ 'HEADER.SYSADMIN_APP' | translate }}
        </button>
      </q9-profile-menu>
    </div>
  </q9-toolbar>

  <q9-navigation-sidebar>
    <q9-navigation-link
      (click)="navigateTo(module.path)"
      [icon]="module.icon"
      *ngFor="let module of modules">
      {{ module.title | translate }}
    </q9-navigation-link>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </q9-navigation-sidebar>
</div>

<ng-template #settings>
  <q9-icon-button icon="settings" [matMenuTriggerFor]="menu"></q9-icon-button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openSpaceManagementApp()">
      {{ 'HEADER.SPACE_MANAGEMENT' | translate }}
    </button>
    <button *ngIf="hasAccessToEnterprise()" mat-menu-item (click)="openCorporateManagementApp()">
      {{ 'HEADER.GLOBAL_MANAGEMENT' | translate }}
    </button>
  </mat-menu>
</ng-template>
